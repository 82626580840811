.clients {
  padding: 2rem 0;
  .container {
    .clients-container {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      align-items: center;
      text-align: center;
      
    }
  }
  @media screen and (min-width: 280px) and (max-width: 1080px) {
    padding: 1rem 2rem;
    .container {
      .clients-container {
        // grid-template-columns: repeat(2, 1fr);
        display: flex;
        flex-direction: column;
        text-align: left;
        gap: 1rem;

        .client {
          display: flex;
          flex-direction: column;
          &:nth-of-type(5) {
            display: none;
          }
          img {
            height: 4rem;
          }
        }
      }
    }
  }
}
