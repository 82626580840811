.image{
    position: relative;
    top: 5vh;    
    height: 30vh;
    width: auto;
}
video{
    position: absolute;
    top: 0vh;
    left: 0vw;
    height: 100%;
    width: 100%;
    object-fit: cover;
    /* filter: brightness(0.2); */
}
/* .cards .card1 .card{
    position: relative;
    top: 10vh;
}
.cards .card2 .card{
    position: relative;
    top: 10vh;
} */
/* .free .container .content .image h2{
    position: relative;
    bottom: 50vh;
} */