@tailwind base;
@tailwind components;
@tailwind utilities;

@import "base/base";

@import "components/scrollToTop";
@import "components/navbar";
@import "components/footer";
@import "components/card";

@import "sections/home";
@import "sections/free";
@import "sections/clients";
@import "sections/superRare";
@import "sections/release";
@import "sections/signup";
@import "sections/like";
